import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"

export default () => {
    return (
        <Layout>
            <Header/>
            <section className="margin-header">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>Headline 1</h1>
                        <h2>Headline 2</h2>
                        <h3>Headline 3</h3>
                        <h4>Headline 4</h4>
                        <p>Lorem ipsum dolor set amet.</p>
                        <button className="margin-b-3">Primary Button</button>
                        <br/>
                        <button className="btn-secondary">Secondary Button</button>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
