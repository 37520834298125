import React, { Component } from 'react';
import { Link } from "gatsby"
import "./nav.scss"

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleKeyDown = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.keyCode === 13 ||
      event.keyCode === 32)
      this.handleMenuClick();
  }
  handleMenuClick = () => {
    const isOpen = this.state.open
    this.setState({ open: !isOpen });
  }
  renderLinks = () => {
    return (
      <React.Fragment>
        <li>
          <Link to="/services/" activeClassName="active">Services</Link>
        </li>
        <li>
          <Link to="/about/" activeClassName="active">About</Link>
        </li>
        <li>
          <Link to="/portfolio/" activeClassName="active">Portfolio</Link>
        </li>
        <li>
          <Link to="/articles/" activeClassName="active">Articles</Link>
        </li>
        <li>
          <Link to="/careers/" activeClassName="active">Careers</Link>
        </li>
        <li>
          <Link to="/contact/" className="btn-secondary" activeClassName="active">
            Contact us
          </Link>
        </li>
      </React.Fragment>
    )
  }
  render() {
    return (
      <nav className={`ob-nav ${this.props.className}`}>
        <div className="menu-toggle hide-md">
          <div
            role="button"
            tabIndex="0"
            id="navToggleOpen"
            aria-pressed="false"
            className={this.state.open ? 'open' : ''}
            onClick={() => this.handleMenuClick()}
            onKeyDown={this.handleKeyDown}>

            {/* Menu */}
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={`flyout-nav hide-md ${this.state.open ? 'open' : ''}`}>
          <ul>{this.renderLinks()}</ul>
        </div>
        <ul className="hide-xs show-md">{this.renderLinks()}</ul>
      </nav>
    )
  }
}

export default Nav
