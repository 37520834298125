import React from "react"
import { Link } from "gatsby"
import Nav from "../components/nav"
import "./header.scss"
import obLogo from '../../static/images/orange-bees-logo.svg'

export default (props) => {
  return (
    <header className="ob-header row-xs-6">
      <a id="skip-nav" className="ob-header-skip-content btn-secondary" href="#maincontent">
        Skip to main content
      </a>
      <div className="row">
        <div className="col-xs-4 col-md-3 col-gutter-lr">
          <Link to="/">
            <img
              className="ob-header-logo"
              src={obLogo}
              alt="Orange Bees"
            />
          </Link>
        </div>
        <Nav className="col-xs-8 col-md-9" />
      </div>
    </header>
  )

}
